/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      name
      netsuiteID
      services {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              id
              name
            }
          }
        }
      }
      ssoDomain
      selfValidation
      defaultRoles
      customerOf
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      name
      netsuiteID
      services {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              id
              name
            }
          }
        }
      }
      ssoDomain
      selfValidation
      defaultRoles
      customerOf
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      name
      netsuiteID
      services {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              id
              name
            }
          }
        }
      }
      ssoDomain
      selfValidation
      defaultRoles
      customerOf
    }
  }
`;
export const createCustomerReport = /* GraphQL */ `
  mutation CreateCustomerReport(
    $input: CreateCustomerReportInput!
    $condition: ModelCustomerReportConditionInput
  ) {
    createCustomerReport(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      tableauCustomerGuid
      tableauUser
      reports {
        name
        url
      }
    }
  }
`;
export const updateCustomerReport = /* GraphQL */ `
  mutation UpdateCustomerReport(
    $input: UpdateCustomerReportInput!
    $condition: ModelCustomerReportConditionInput
  ) {
    updateCustomerReport(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      tableauCustomerGuid
      tableauUser
      reports {
        name
        url
      }
    }
  }
`;
export const deleteCustomerReport = /* GraphQL */ `
  mutation DeleteCustomerReport(
    $input: DeleteCustomerReportInput!
    $condition: ModelCustomerReportConditionInput
  ) {
    deleteCustomerReport(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      tableauCustomerGuid
      tableauUser
      reports {
        name
        url
      }
    }
  }
`;
export const createCompanyData = /* GraphQL */ `
  mutation CreateCompanyData(
    $input: CreateCompanyDataInput!
    $condition: ModelCompanyDataConditionInput
  ) {
    createCompanyData(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      name
      companyCode
      businessUnit
      gln
      coc
      vatID
      address
      city
      postalCode
      opCo
      emailAddress
      businessArea
      synonyms {
        operator
        value
      }
      exportType
      addressLine2
      country
      IBAN
      BIC
      termsAndConditions
      isoCode
      active
      default
    }
  }
`;
export const updateCompanyData = /* GraphQL */ `
  mutation UpdateCompanyData(
    $input: UpdateCompanyDataInput!
    $condition: ModelCompanyDataConditionInput
  ) {
    updateCompanyData(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      name
      companyCode
      businessUnit
      gln
      coc
      vatID
      address
      city
      postalCode
      opCo
      emailAddress
      businessArea
      synonyms {
        operator
        value
      }
      exportType
      addressLine2
      country
      IBAN
      BIC
      termsAndConditions
      isoCode
      active
      default
    }
  }
`;
export const deleteCompanyData = /* GraphQL */ `
  mutation DeleteCompanyData(
    $input: DeleteCompanyDataInput!
    $condition: ModelCompanyDataConditionInput
  ) {
    deleteCompanyData(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      name
      companyCode
      businessUnit
      gln
      coc
      vatID
      address
      city
      postalCode
      opCo
      emailAddress
      businessArea
      synonyms {
        operator
        value
      }
      exportType
      addressLine2
      country
      IBAN
      BIC
      termsAndConditions
      isoCode
      active
      default
    }
  }
`;
export const createDomainSetting = /* GraphQL */ `
  mutation CreateDomainSetting(
    $input: CreateDomainSettingInput!
    $condition: ModelDomainSettingConditionInput
  ) {
    createDomainSetting(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      denied
      domain
      ignoredFiles
      fileTypeProcess {
        process
        friendlyProcessName
        filePattern
      }
      excluded {
        type
        search
      }
    }
  }
`;
export const updateDomainSetting = /* GraphQL */ `
  mutation UpdateDomainSetting(
    $input: UpdateDomainSettingInput!
    $condition: ModelDomainSettingConditionInput
  ) {
    updateDomainSetting(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      denied
      domain
      ignoredFiles
      fileTypeProcess {
        process
        friendlyProcessName
        filePattern
      }
      excluded {
        type
        search
      }
    }
  }
`;
export const deleteDomainSetting = /* GraphQL */ `
  mutation DeleteDomainSetting(
    $input: DeleteDomainSettingInput!
    $condition: ModelDomainSettingConditionInput
  ) {
    deleteDomainSetting(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      denied
      domain
      ignoredFiles
      fileTypeProcess {
        process
        friendlyProcessName
        filePattern
      }
      excluded {
        type
        search
      }
    }
  }
`;
export const createBamNumberRecognition = /* GraphQL */ `
  mutation CreateBamNumberRecognition(
    $input: CreateBamNumberRecognitionInput!
    $condition: ModelBamNumberRecognitionConditionInput
  ) {
    createBamNumberRecognition(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      companyCode
      fieldType
      comparison
      value
    }
  }
`;
export const updateBamNumberRecognition = /* GraphQL */ `
  mutation UpdateBamNumberRecognition(
    $input: UpdateBamNumberRecognitionInput!
    $condition: ModelBamNumberRecognitionConditionInput
  ) {
    updateBamNumberRecognition(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      companyCode
      fieldType
      comparison
      value
    }
  }
`;
export const deleteBamNumberRecognition = /* GraphQL */ `
  mutation DeleteBamNumberRecognition(
    $input: DeleteBamNumberRecognitionInput!
    $condition: ModelBamNumberRecognitionConditionInput
  ) {
    deleteBamNumberRecognition(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      companyCode
      fieldType
      comparison
      value
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      icGuid
      createdAt
      createdEpoch
      updatedAt
      amountGross
      amountNet1
      amountNet2
      amountNet3
      amountNetTotal
      amountVat1
      amountVat2
      amountVat3
      amountVatTotal
      appliedMapping
      bankName
      btBUMID
      btInvoiceRef
      btOrigin
      btReceiverRef
      btScanDate
      businessArea
      businessUnit
      companyID
      companyCode
      companyName
      companyNameOverride
      currency
      customerID
      deliveryDate
      deliveryTerms
      deliveryEndDate
      deliveryLocation
      deliveryInstruction
      documentNumber
      documentSubType
      documentType
      dueDate
      exportApproved
      exportMapping
      exportType
      externalSystem
      externalSystemID
      forceValid
      gAccount
      gAmount
      invoiceDate
      invoiceNumber
      invoiceType
      integrationDetail
      ivsProcessID
      lastError
      noteNumber
      noteToSupplier
      opCo
      owner
      orderDate
      orderType
      paymentTerms
      pdfUploadedFrontEnd
      purchasingCategory
      poCreator
      poOwner
      purpose
      receivedMessageID
      receiverAddress1
      receiverAddress2
      receiverCity
      receiverCompanyName
      receiverCompanyCode
      receiverContractNumber
      receiverCountryCode
      receiverContactName
      receiverContactFirstName
      receiverContactPhoneNumber
      receiverContactEmailAddress
      receiverGLN
      receiverKVK
      receiverNetServiceId
      receiverOrdernumber
      receiverPostalCode
      receiverVatId
      receiverEmailAddress
      receiverEndPointIsoCode
      receiverEndPointID
      remark
      returnDate
      returnEmailAddress
      returnStatus
      s3ResourceAttachment {
        s3Resource
      }
      s3ResourceWebserviceResponse
      s3ResourceExportedDocument
      s3ResourceGeneratedImage
      s3ResourceOrgImage
      s3ResourceOrgXML
      source
      status
      secondaryStatus
      senderAddress1
      senderAddress2
      senderCompanyCode
      senderContractNumber
      senderCountryCode
      senderContactName
      senderContactFirstName
      senderContactPhoneNumber
      senderContactEmailAddress
      senderDocCode
      senderDocNum
      senderEmailAddress
      senderGLN
      senderIBAN
      senderName
      senderNote
      senderReference
      senderTerms
      senderPostalCode
      senderVatId
      subscriptionType
      supplierAddress1
      supplierAddress2
      supplierBBAN
      supplierCity
      supplierCountryCode
      supplierDescription
      supplierEmailAddress
      supplierGLN
      supplierIBAN
      supplierKVK
      supplierName
      supplierNetServiceId
      supplierCode
      supplierRecognitionInput
      supplierOrdernumber
      supplierPostalCode
      supplierVatId
      swiftCode
      vatRate1
      vatRate2
      vatRate3
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      lines {
        id
        lineNumber
        lineNetTotal
        lineVatRate
        lineVatAmount
        lineUnitPrice
        lineQuantity
        lineDescription
        lineUoM
        lineArticleCode
        lineSupplierArticleCode
        lineOrderNumber
        lineDiscountRate
        lineDiscountAmount
        lineDiscountName
        lineFreeNumber1
        lineFreeNumber2
        lineFreeNumber3
        lineFreeText1
        lineFreeText2
        lineFreeText3
      }
      locked
      lockedBy
      lockedSince
      hasBeenReProcessed
      sendScanReportToBasware
      scanReportCode
      scanReportDescription
      scanReportComment
      title
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      icGuid
      createdAt
      createdEpoch
      updatedAt
      amountGross
      amountNet1
      amountNet2
      amountNet3
      amountNetTotal
      amountVat1
      amountVat2
      amountVat3
      amountVatTotal
      appliedMapping
      bankName
      btBUMID
      btInvoiceRef
      btOrigin
      btReceiverRef
      btScanDate
      businessArea
      businessUnit
      companyID
      companyCode
      companyName
      companyNameOverride
      currency
      customerID
      deliveryDate
      deliveryTerms
      deliveryEndDate
      deliveryLocation
      deliveryInstruction
      documentNumber
      documentSubType
      documentType
      dueDate
      exportApproved
      exportMapping
      exportType
      externalSystem
      externalSystemID
      forceValid
      gAccount
      gAmount
      invoiceDate
      invoiceNumber
      invoiceType
      integrationDetail
      ivsProcessID
      lastError
      noteNumber
      noteToSupplier
      opCo
      owner
      orderDate
      orderType
      paymentTerms
      pdfUploadedFrontEnd
      purchasingCategory
      poCreator
      poOwner
      purpose
      receivedMessageID
      receiverAddress1
      receiverAddress2
      receiverCity
      receiverCompanyName
      receiverCompanyCode
      receiverContractNumber
      receiverCountryCode
      receiverContactName
      receiverContactFirstName
      receiverContactPhoneNumber
      receiverContactEmailAddress
      receiverGLN
      receiverKVK
      receiverNetServiceId
      receiverOrdernumber
      receiverPostalCode
      receiverVatId
      receiverEmailAddress
      receiverEndPointIsoCode
      receiverEndPointID
      remark
      returnDate
      returnEmailAddress
      returnStatus
      s3ResourceAttachment {
        s3Resource
      }
      s3ResourceWebserviceResponse
      s3ResourceExportedDocument
      s3ResourceGeneratedImage
      s3ResourceOrgImage
      s3ResourceOrgXML
      source
      status
      secondaryStatus
      senderAddress1
      senderAddress2
      senderCompanyCode
      senderContractNumber
      senderCountryCode
      senderContactName
      senderContactFirstName
      senderContactPhoneNumber
      senderContactEmailAddress
      senderDocCode
      senderDocNum
      senderEmailAddress
      senderGLN
      senderIBAN
      senderName
      senderNote
      senderReference
      senderTerms
      senderPostalCode
      senderVatId
      subscriptionType
      supplierAddress1
      supplierAddress2
      supplierBBAN
      supplierCity
      supplierCountryCode
      supplierDescription
      supplierEmailAddress
      supplierGLN
      supplierIBAN
      supplierKVK
      supplierName
      supplierNetServiceId
      supplierCode
      supplierRecognitionInput
      supplierOrdernumber
      supplierPostalCode
      supplierVatId
      swiftCode
      vatRate1
      vatRate2
      vatRate3
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      lines {
        id
        lineNumber
        lineNetTotal
        lineVatRate
        lineVatAmount
        lineUnitPrice
        lineQuantity
        lineDescription
        lineUoM
        lineArticleCode
        lineSupplierArticleCode
        lineOrderNumber
        lineDiscountRate
        lineDiscountAmount
        lineDiscountName
        lineFreeNumber1
        lineFreeNumber2
        lineFreeNumber3
        lineFreeText1
        lineFreeText2
        lineFreeText3
      }
      locked
      lockedBy
      lockedSince
      hasBeenReProcessed
      sendScanReportToBasware
      scanReportCode
      scanReportDescription
      scanReportComment
      title
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      icGuid
      createdAt
      createdEpoch
      updatedAt
      amountGross
      amountNet1
      amountNet2
      amountNet3
      amountNetTotal
      amountVat1
      amountVat2
      amountVat3
      amountVatTotal
      appliedMapping
      bankName
      btBUMID
      btInvoiceRef
      btOrigin
      btReceiverRef
      btScanDate
      businessArea
      businessUnit
      companyID
      companyCode
      companyName
      companyNameOverride
      currency
      customerID
      deliveryDate
      deliveryTerms
      deliveryEndDate
      deliveryLocation
      deliveryInstruction
      documentNumber
      documentSubType
      documentType
      dueDate
      exportApproved
      exportMapping
      exportType
      externalSystem
      externalSystemID
      forceValid
      gAccount
      gAmount
      invoiceDate
      invoiceNumber
      invoiceType
      integrationDetail
      ivsProcessID
      lastError
      noteNumber
      noteToSupplier
      opCo
      owner
      orderDate
      orderType
      paymentTerms
      pdfUploadedFrontEnd
      purchasingCategory
      poCreator
      poOwner
      purpose
      receivedMessageID
      receiverAddress1
      receiverAddress2
      receiverCity
      receiverCompanyName
      receiverCompanyCode
      receiverContractNumber
      receiverCountryCode
      receiverContactName
      receiverContactFirstName
      receiverContactPhoneNumber
      receiverContactEmailAddress
      receiverGLN
      receiverKVK
      receiverNetServiceId
      receiverOrdernumber
      receiverPostalCode
      receiverVatId
      receiverEmailAddress
      receiverEndPointIsoCode
      receiverEndPointID
      remark
      returnDate
      returnEmailAddress
      returnStatus
      s3ResourceAttachment {
        s3Resource
      }
      s3ResourceWebserviceResponse
      s3ResourceExportedDocument
      s3ResourceGeneratedImage
      s3ResourceOrgImage
      s3ResourceOrgXML
      source
      status
      secondaryStatus
      senderAddress1
      senderAddress2
      senderCompanyCode
      senderContractNumber
      senderCountryCode
      senderContactName
      senderContactFirstName
      senderContactPhoneNumber
      senderContactEmailAddress
      senderDocCode
      senderDocNum
      senderEmailAddress
      senderGLN
      senderIBAN
      senderName
      senderNote
      senderReference
      senderTerms
      senderPostalCode
      senderVatId
      subscriptionType
      supplierAddress1
      supplierAddress2
      supplierBBAN
      supplierCity
      supplierCountryCode
      supplierDescription
      supplierEmailAddress
      supplierGLN
      supplierIBAN
      supplierKVK
      supplierName
      supplierNetServiceId
      supplierCode
      supplierRecognitionInput
      supplierOrdernumber
      supplierPostalCode
      supplierVatId
      swiftCode
      vatRate1
      vatRate2
      vatRate3
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      lines {
        id
        lineNumber
        lineNetTotal
        lineVatRate
        lineVatAmount
        lineUnitPrice
        lineQuantity
        lineDescription
        lineUoM
        lineArticleCode
        lineSupplierArticleCode
        lineOrderNumber
        lineDiscountRate
        lineDiscountAmount
        lineDiscountName
        lineFreeNumber1
        lineFreeNumber2
        lineFreeNumber3
        lineFreeText1
        lineFreeText2
        lineFreeText3
      }
      locked
      lockedBy
      lockedSince
      hasBeenReProcessed
      sendScanReportToBasware
      scanReportCode
      scanReportDescription
      scanReportComment
      title
    }
  }
`;
export const createCrudHistory = /* GraphQL */ `
  mutation CreateCrudHistory(
    $input: CreateCrudHistoryInput!
    $condition: ModelCrudHistoryConditionInput
  ) {
    createCrudHistory(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      change
      section
      tableLineID
      name
      user
      changes {
        field
        oldValue
        newValue
      }
    }
  }
`;
export const updateCrudHistory = /* GraphQL */ `
  mutation UpdateCrudHistory(
    $input: UpdateCrudHistoryInput!
    $condition: ModelCrudHistoryConditionInput
  ) {
    updateCrudHistory(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      change
      section
      tableLineID
      name
      user
      changes {
        field
        oldValue
        newValue
      }
    }
  }
`;
export const deleteCrudHistory = /* GraphQL */ `
  mutation DeleteCrudHistory(
    $input: DeleteCrudHistoryInput!
    $condition: ModelCrudHistoryConditionInput
  ) {
    deleteCrudHistory(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      change
      section
      tableLineID
      name
      user
      changes {
        field
        oldValue
        newValue
      }
    }
  }
`;
export const createRecognitionDocument = /* GraphQL */ `
  mutation CreateRecognitionDocument(
    $input: CreateRecognitionDocumentInput!
    $condition: ModelRecognitionDocumentConditionInput
  ) {
    createRecognitionDocument(input: $input, condition: $condition) {
      id
      icGuid
      customerID
      createdAt
      createdEpoch
      advanced
      updatedAt
      documentSubType
      companyCode
      documentType
      integrationDetail
      lastError
      status
      secondaryStatus
      ocrStatus
      validator
      receivedMessageID
      bodyS3Resource
      s3ResourceOrgImage
      s3ResourceAttachment {
        s3Resource
      }
      locked
      lockedBy
      lockedSince
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      remark
      exportType
      supplierIBAN {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      gAccount {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      supplierVatId {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      supplierCoc {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      credit {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      invoiceNumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      dueDate {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      invoiceDate {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      receiverOrdernumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      receiverContractNumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      currency {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNetTotal {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVatTotal {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountGross {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
    }
  }
`;
export const updateRecognitionDocument = /* GraphQL */ `
  mutation UpdateRecognitionDocument(
    $input: UpdateRecognitionDocumentInput!
    $condition: ModelRecognitionDocumentConditionInput
  ) {
    updateRecognitionDocument(input: $input, condition: $condition) {
      id
      icGuid
      customerID
      createdAt
      createdEpoch
      advanced
      updatedAt
      documentSubType
      companyCode
      documentType
      integrationDetail
      lastError
      status
      secondaryStatus
      ocrStatus
      validator
      receivedMessageID
      bodyS3Resource
      s3ResourceOrgImage
      s3ResourceAttachment {
        s3Resource
      }
      locked
      lockedBy
      lockedSince
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      remark
      exportType
      supplierIBAN {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      gAccount {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      supplierVatId {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      supplierCoc {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      credit {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      invoiceNumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      dueDate {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      invoiceDate {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      receiverOrdernumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      receiverContractNumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      currency {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNetTotal {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVatTotal {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountGross {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
    }
  }
`;
export const deleteRecognitionDocument = /* GraphQL */ `
  mutation DeleteRecognitionDocument(
    $input: DeleteRecognitionDocumentInput!
    $condition: ModelRecognitionDocumentConditionInput
  ) {
    deleteRecognitionDocument(input: $input, condition: $condition) {
      id
      icGuid
      customerID
      createdAt
      createdEpoch
      advanced
      updatedAt
      documentSubType
      companyCode
      documentType
      integrationDetail
      lastError
      status
      secondaryStatus
      ocrStatus
      validator
      receivedMessageID
      bodyS3Resource
      s3ResourceOrgImage
      s3ResourceAttachment {
        s3Resource
      }
      locked
      lockedBy
      lockedSince
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      remark
      exportType
      supplierIBAN {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      gAccount {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      supplierVatId {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      supplierCoc {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      credit {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      invoiceNumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      dueDate {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      invoiceDate {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      receiverOrdernumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      receiverContractNumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      currency {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNetTotal {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVatTotal {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountGross {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
    }
  }
`;
export const createReturnServiceEmailTemplates = /* GraphQL */ `
  mutation CreateReturnServiceEmailTemplates(
    $input: CreateReturnServiceEmailTemplatesInput!
    $condition: ModelReturnServiceEmailTemplatesConditionInput
  ) {
    createReturnServiceEmailTemplates(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      templateName
      senderAddress
      replyToAddress
      to
      cc
      bcc
      subject
      htmlBody
      automatic
    }
  }
`;
export const updateReturnServiceEmailTemplates = /* GraphQL */ `
  mutation UpdateReturnServiceEmailTemplates(
    $input: UpdateReturnServiceEmailTemplatesInput!
    $condition: ModelReturnServiceEmailTemplatesConditionInput
  ) {
    updateReturnServiceEmailTemplates(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      templateName
      senderAddress
      replyToAddress
      to
      cc
      bcc
      subject
      htmlBody
      automatic
    }
  }
`;
export const deleteReturnServiceEmailTemplates = /* GraphQL */ `
  mutation DeleteReturnServiceEmailTemplates(
    $input: DeleteReturnServiceEmailTemplatesInput!
    $condition: ModelReturnServiceEmailTemplatesConditionInput
  ) {
    deleteReturnServiceEmailTemplates(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      templateName
      senderAddress
      replyToAddress
      to
      cc
      bcc
      subject
      htmlBody
      automatic
    }
  }
`;
export const createGLNSupplier = /* GraphQL */ `
  mutation CreateGLNSupplier(
    $input: CreateGLNSupplierInput!
    $condition: ModelGLNSupplierConditionInput
  ) {
    createGLNSupplier(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      opCo
      companyCode
      supplierCode
      supplierName
      glnNumbers
    }
  }
`;
export const updateGLNSupplier = /* GraphQL */ `
  mutation UpdateGLNSupplier(
    $input: UpdateGLNSupplierInput!
    $condition: ModelGLNSupplierConditionInput
  ) {
    updateGLNSupplier(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      opCo
      companyCode
      supplierCode
      supplierName
      glnNumbers
    }
  }
`;
export const deleteGLNSupplier = /* GraphQL */ `
  mutation DeleteGLNSupplier(
    $input: DeleteGLNSupplierInput!
    $condition: ModelGLNSupplierConditionInput
  ) {
    deleteGLNSupplier(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      opCo
      companyCode
      supplierCode
      supplierName
      glnNumbers
    }
  }
`;
export const createReceivedMessages = /* GraphQL */ `
  mutation CreateReceivedMessages(
    $input: CreateReceivedMessagesInput!
    $condition: ModelReceivedMessagesConditionInput
  ) {
    createReceivedMessages(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      attribute1
      attribute2
      attribute3
      attribute4
      attribute5
      bodyS3Resource
      domain
      errorText
      hasIgnoredFiles
      ignoreds3ResourceAttachment {
        s3Resource
      }
      s3ResourceAttachment {
        s3Resource
      }
      secondaryStatus
      senderAddress
      status
      subject
      companyCode
      integrationID
      recipientAddress
      forceValid
      processID
      processName
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      test
    }
  }
`;
export const updateReceivedMessages = /* GraphQL */ `
  mutation UpdateReceivedMessages(
    $input: UpdateReceivedMessagesInput!
    $condition: ModelReceivedMessagesConditionInput
  ) {
    updateReceivedMessages(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      attribute1
      attribute2
      attribute3
      attribute4
      attribute5
      bodyS3Resource
      domain
      errorText
      hasIgnoredFiles
      ignoreds3ResourceAttachment {
        s3Resource
      }
      s3ResourceAttachment {
        s3Resource
      }
      secondaryStatus
      senderAddress
      status
      subject
      companyCode
      integrationID
      recipientAddress
      forceValid
      processID
      processName
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      test
    }
  }
`;
export const deleteReceivedMessages = /* GraphQL */ `
  mutation DeleteReceivedMessages(
    $input: DeleteReceivedMessagesInput!
    $condition: ModelReceivedMessagesConditionInput
  ) {
    deleteReceivedMessages(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      attribute1
      attribute2
      attribute3
      attribute4
      attribute5
      bodyS3Resource
      domain
      errorText
      hasIgnoredFiles
      ignoreds3ResourceAttachment {
        s3Resource
      }
      s3ResourceAttachment {
        s3Resource
      }
      secondaryStatus
      senderAddress
      status
      subject
      companyCode
      integrationID
      recipientAddress
      forceValid
      processID
      processName
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      test
    }
  }
`;
export const createFieldActions = /* GraphQL */ `
  mutation CreateFieldActions(
    $input: CreateFieldActionsInput!
    $condition: ModelFieldActionsConditionInput
  ) {
    createFieldActions(input: $input, condition: $condition) {
      id
      filter
      type
      createdAt
      updatedAt
      customerID
      fieldName
      fieldLocation
      input
      value
      raiseOnError
      errorText
      stepIndex
      beforeProcess
      afterProcess
      method
      documentType
      documentSubType
      description
      integrationID
      requestUrl
      requestHeaders
    }
  }
`;
export const updateFieldActions = /* GraphQL */ `
  mutation UpdateFieldActions(
    $input: UpdateFieldActionsInput!
    $condition: ModelFieldActionsConditionInput
  ) {
    updateFieldActions(input: $input, condition: $condition) {
      id
      filter
      type
      createdAt
      updatedAt
      customerID
      fieldName
      fieldLocation
      input
      value
      raiseOnError
      errorText
      stepIndex
      beforeProcess
      afterProcess
      method
      documentType
      documentSubType
      description
      integrationID
      requestUrl
      requestHeaders
    }
  }
`;
export const deleteFieldActions = /* GraphQL */ `
  mutation DeleteFieldActions(
    $input: DeleteFieldActionsInput!
    $condition: ModelFieldActionsConditionInput
  ) {
    deleteFieldActions(input: $input, condition: $condition) {
      id
      filter
      type
      createdAt
      updatedAt
      customerID
      fieldName
      fieldLocation
      input
      value
      raiseOnError
      errorText
      stepIndex
      beforeProcess
      afterProcess
      method
      documentType
      documentSubType
      description
      integrationID
      requestUrl
      requestHeaders
    }
  }
`;
export const createCompanyRecognitionOutcome = /* GraphQL */ `
  mutation CreateCompanyRecognitionOutcome(
    $input: CreateCompanyRecognitionOutcomeInput!
    $condition: ModelCompanyRecognitionOutcomeConditionInput
  ) {
    createCompanyRecognitionOutcome(input: $input, condition: $condition) {
      id
      customerID
      documentID
      success
      matchedCompanies {
        companyID
        synonym
        token
        ratio
        lengthRatio
      }
      recognizedCompanyID
      recognizedSynonym
      recognizedToken
      recognizedCertaintyScore
      textractUsed
      quickRatio
      tokensFromInvoice
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyRecognitionOutcome = /* GraphQL */ `
  mutation UpdateCompanyRecognitionOutcome(
    $input: UpdateCompanyRecognitionOutcomeInput!
    $condition: ModelCompanyRecognitionOutcomeConditionInput
  ) {
    updateCompanyRecognitionOutcome(input: $input, condition: $condition) {
      id
      customerID
      documentID
      success
      matchedCompanies {
        companyID
        synonym
        token
        ratio
        lengthRatio
      }
      recognizedCompanyID
      recognizedSynonym
      recognizedToken
      recognizedCertaintyScore
      textractUsed
      quickRatio
      tokensFromInvoice
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyRecognitionOutcome = /* GraphQL */ `
  mutation DeleteCompanyRecognitionOutcome(
    $input: DeleteCompanyRecognitionOutcomeInput!
    $condition: ModelCompanyRecognitionOutcomeConditionInput
  ) {
    deleteCompanyRecognitionOutcome(input: $input, condition: $condition) {
      id
      customerID
      documentID
      success
      matchedCompanies {
        companyID
        synonym
        token
        ratio
        lengthRatio
      }
      recognizedCompanyID
      recognizedSynonym
      recognizedToken
      recognizedCertaintyScore
      textractUsed
      quickRatio
      tokensFromInvoice
      createdAt
      updatedAt
    }
  }
`;
export const createDocumentConfiguration = /* GraphQL */ `
  mutation CreateDocumentConfiguration(
    $input: CreateDocumentConfigurationInput!
    $condition: ModelDocumentConfigurationConditionInput
  ) {
    createDocumentConfiguration(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      documentType
      amountGross {
        visible
        editable
      }
      amountNet1 {
        visible
        editable
      }
      amountNet2 {
        visible
        editable
      }
      amountNet3 {
        visible
        editable
      }
      amountNetTotal {
        visible
        editable
      }
      amountVat1 {
        visible
        editable
      }
      amountVat2 {
        visible
        editable
      }
      amountVat3 {
        visible
        editable
      }
      amountVatTotal {
        visible
        editable
      }
      bankName {
        visible
        editable
      }
      businessArea {
        visible
        editable
      }
      businessUnit {
        visible
        editable
      }
      company {
        visible
        editable
      }
      companyNameOverride {
        visible
        editable
      }
      currency {
        visible
        editable
      }
      deliveryDate {
        visible
        editable
      }
      deliveryEndDate {
        visible
        editable
      }
      deliveryLocation {
        visible
        editable
      }
      deliveryInstruction {
        visible
        editable
      }
      deliveryTerms {
        visible
        editable
      }
      documentNumber {
        visible
        editable
      }
      dueDate {
        visible
        editable
      }
      forceValid {
        visible
        editable
      }
      gAmount {
        visible
        editable
      }
      gAccount {
        visible
        editable
      }
      invoiceDate {
        visible
        editable
      }
      invoiceNumber {
        visible
        editable
      }
      invoiceType {
        visible
        editable
      }
      noteNumber {
        visible
        editable
      }
      noteToSupplier {
        visible
        editable
      }
      opCo {
        visible
        editable
      }
      orderDate {
        visible
        editable
      }
      orderType {
        visible
        editable
      }
      paymentTerms {
        visible
        editable
      }
      poCreator {
        visible
        editable
      }
      poOwner {
        visible
        editable
      }
      purchasingCategory {
        visible
        editable
      }
      purpose {
        visible
        editable
      }
      receiverAddress1 {
        visible
        editable
      }
      receiverAddress2 {
        visible
        editable
      }
      receiverCity {
        visible
        editable
      }
      receiverCompanyName {
        visible
        editable
      }
      receiverCompanyCode {
        visible
        editable
      }
      receiverContractNumber {
        visible
        editable
      }
      receiverCountryCode {
        visible
        editable
      }
      receiverGLN {
        visible
        editable
      }
      receiverKVK {
        visible
        editable
      }
      receiverNetServiceId {
        visible
        editable
      }
      receiverOrdernumber {
        visible
        editable
      }
      receiverPostalCode {
        visible
        editable
      }
      receiverVatId {
        visible
        editable
      }
      receiverEmailAddress {
        visible
        editable
      }
      senderAddress1 {
        visible
        editable
      }
      senderAddress2 {
        visible
        editable
      }
      senderCompanyCode {
        visible
        editable
      }
      senderContractNumber {
        visible
        editable
      }
      senderCountryCode {
        visible
        editable
      }
      senderEmailAddress {
        visible
        editable
      }
      senderGLN {
        visible
        editable
      }
      senderIBAN {
        visible
        editable
      }
      senderName {
        visible
        editable
      }
      senderNote {
        visible
        editable
      }
      senderReference {
        visible
        editable
      }
      senderTerms {
        visible
        editable
      }
      senderVatId {
        visible
        editable
      }
      supplierAddress1 {
        visible
        editable
      }
      supplierAddress2 {
        visible
        editable
      }
      supplierBBAN {
        visible
        editable
      }
      supplierCity {
        visible
        editable
      }
      supplierCountryCode {
        visible
        editable
      }
      supplierDescription {
        visible
        editable
      }
      supplierEmailAddress {
        visible
        editable
      }
      supplierGLN {
        visible
        editable
      }
      supplierIBAN {
        visible
        editable
      }
      supplierKVK {
        visible
        editable
      }
      supplierName {
        visible
        editable
      }
      supplierNetServiceId {
        visible
        editable
      }
      supplierCode {
        visible
        editable
      }
      supplierRecognitionInput {
        visible
        editable
      }
      supplierOrdernumber {
        visible
        editable
      }
      supplierPostalCode {
        visible
        editable
      }
      supplierVatId {
        visible
        editable
      }
      swiftCode {
        visible
        editable
      }
      title {
        visible
        editable
      }
      vatRate1 {
        visible
        editable
      }
      vatRate2 {
        visible
        editable
      }
      vatRate3 {
        visible
        editable
      }
    }
  }
`;
export const updateDocumentConfiguration = /* GraphQL */ `
  mutation UpdateDocumentConfiguration(
    $input: UpdateDocumentConfigurationInput!
    $condition: ModelDocumentConfigurationConditionInput
  ) {
    updateDocumentConfiguration(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      documentType
      amountGross {
        visible
        editable
      }
      amountNet1 {
        visible
        editable
      }
      amountNet2 {
        visible
        editable
      }
      amountNet3 {
        visible
        editable
      }
      amountNetTotal {
        visible
        editable
      }
      amountVat1 {
        visible
        editable
      }
      amountVat2 {
        visible
        editable
      }
      amountVat3 {
        visible
        editable
      }
      amountVatTotal {
        visible
        editable
      }
      bankName {
        visible
        editable
      }
      businessArea {
        visible
        editable
      }
      businessUnit {
        visible
        editable
      }
      company {
        visible
        editable
      }
      companyNameOverride {
        visible
        editable
      }
      currency {
        visible
        editable
      }
      deliveryDate {
        visible
        editable
      }
      deliveryEndDate {
        visible
        editable
      }
      deliveryLocation {
        visible
        editable
      }
      deliveryInstruction {
        visible
        editable
      }
      deliveryTerms {
        visible
        editable
      }
      documentNumber {
        visible
        editable
      }
      dueDate {
        visible
        editable
      }
      forceValid {
        visible
        editable
      }
      gAmount {
        visible
        editable
      }
      gAccount {
        visible
        editable
      }
      invoiceDate {
        visible
        editable
      }
      invoiceNumber {
        visible
        editable
      }
      invoiceType {
        visible
        editable
      }
      noteNumber {
        visible
        editable
      }
      noteToSupplier {
        visible
        editable
      }
      opCo {
        visible
        editable
      }
      orderDate {
        visible
        editable
      }
      orderType {
        visible
        editable
      }
      paymentTerms {
        visible
        editable
      }
      poCreator {
        visible
        editable
      }
      poOwner {
        visible
        editable
      }
      purchasingCategory {
        visible
        editable
      }
      purpose {
        visible
        editable
      }
      receiverAddress1 {
        visible
        editable
      }
      receiverAddress2 {
        visible
        editable
      }
      receiverCity {
        visible
        editable
      }
      receiverCompanyName {
        visible
        editable
      }
      receiverCompanyCode {
        visible
        editable
      }
      receiverContractNumber {
        visible
        editable
      }
      receiverCountryCode {
        visible
        editable
      }
      receiverGLN {
        visible
        editable
      }
      receiverKVK {
        visible
        editable
      }
      receiverNetServiceId {
        visible
        editable
      }
      receiverOrdernumber {
        visible
        editable
      }
      receiverPostalCode {
        visible
        editable
      }
      receiverVatId {
        visible
        editable
      }
      receiverEmailAddress {
        visible
        editable
      }
      senderAddress1 {
        visible
        editable
      }
      senderAddress2 {
        visible
        editable
      }
      senderCompanyCode {
        visible
        editable
      }
      senderContractNumber {
        visible
        editable
      }
      senderCountryCode {
        visible
        editable
      }
      senderEmailAddress {
        visible
        editable
      }
      senderGLN {
        visible
        editable
      }
      senderIBAN {
        visible
        editable
      }
      senderName {
        visible
        editable
      }
      senderNote {
        visible
        editable
      }
      senderReference {
        visible
        editable
      }
      senderTerms {
        visible
        editable
      }
      senderVatId {
        visible
        editable
      }
      supplierAddress1 {
        visible
        editable
      }
      supplierAddress2 {
        visible
        editable
      }
      supplierBBAN {
        visible
        editable
      }
      supplierCity {
        visible
        editable
      }
      supplierCountryCode {
        visible
        editable
      }
      supplierDescription {
        visible
        editable
      }
      supplierEmailAddress {
        visible
        editable
      }
      supplierGLN {
        visible
        editable
      }
      supplierIBAN {
        visible
        editable
      }
      supplierKVK {
        visible
        editable
      }
      supplierName {
        visible
        editable
      }
      supplierNetServiceId {
        visible
        editable
      }
      supplierCode {
        visible
        editable
      }
      supplierRecognitionInput {
        visible
        editable
      }
      supplierOrdernumber {
        visible
        editable
      }
      supplierPostalCode {
        visible
        editable
      }
      supplierVatId {
        visible
        editable
      }
      swiftCode {
        visible
        editable
      }
      title {
        visible
        editable
      }
      vatRate1 {
        visible
        editable
      }
      vatRate2 {
        visible
        editable
      }
      vatRate3 {
        visible
        editable
      }
    }
  }
`;
export const deleteDocumentConfiguration = /* GraphQL */ `
  mutation DeleteDocumentConfiguration(
    $input: DeleteDocumentConfigurationInput!
    $condition: ModelDocumentConfigurationConditionInput
  ) {
    deleteDocumentConfiguration(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      documentType
      amountGross {
        visible
        editable
      }
      amountNet1 {
        visible
        editable
      }
      amountNet2 {
        visible
        editable
      }
      amountNet3 {
        visible
        editable
      }
      amountNetTotal {
        visible
        editable
      }
      amountVat1 {
        visible
        editable
      }
      amountVat2 {
        visible
        editable
      }
      amountVat3 {
        visible
        editable
      }
      amountVatTotal {
        visible
        editable
      }
      bankName {
        visible
        editable
      }
      businessArea {
        visible
        editable
      }
      businessUnit {
        visible
        editable
      }
      company {
        visible
        editable
      }
      companyNameOverride {
        visible
        editable
      }
      currency {
        visible
        editable
      }
      deliveryDate {
        visible
        editable
      }
      deliveryEndDate {
        visible
        editable
      }
      deliveryLocation {
        visible
        editable
      }
      deliveryInstruction {
        visible
        editable
      }
      deliveryTerms {
        visible
        editable
      }
      documentNumber {
        visible
        editable
      }
      dueDate {
        visible
        editable
      }
      forceValid {
        visible
        editable
      }
      gAmount {
        visible
        editable
      }
      gAccount {
        visible
        editable
      }
      invoiceDate {
        visible
        editable
      }
      invoiceNumber {
        visible
        editable
      }
      invoiceType {
        visible
        editable
      }
      noteNumber {
        visible
        editable
      }
      noteToSupplier {
        visible
        editable
      }
      opCo {
        visible
        editable
      }
      orderDate {
        visible
        editable
      }
      orderType {
        visible
        editable
      }
      paymentTerms {
        visible
        editable
      }
      poCreator {
        visible
        editable
      }
      poOwner {
        visible
        editable
      }
      purchasingCategory {
        visible
        editable
      }
      purpose {
        visible
        editable
      }
      receiverAddress1 {
        visible
        editable
      }
      receiverAddress2 {
        visible
        editable
      }
      receiverCity {
        visible
        editable
      }
      receiverCompanyName {
        visible
        editable
      }
      receiverCompanyCode {
        visible
        editable
      }
      receiverContractNumber {
        visible
        editable
      }
      receiverCountryCode {
        visible
        editable
      }
      receiverGLN {
        visible
        editable
      }
      receiverKVK {
        visible
        editable
      }
      receiverNetServiceId {
        visible
        editable
      }
      receiverOrdernumber {
        visible
        editable
      }
      receiverPostalCode {
        visible
        editable
      }
      receiverVatId {
        visible
        editable
      }
      receiverEmailAddress {
        visible
        editable
      }
      senderAddress1 {
        visible
        editable
      }
      senderAddress2 {
        visible
        editable
      }
      senderCompanyCode {
        visible
        editable
      }
      senderContractNumber {
        visible
        editable
      }
      senderCountryCode {
        visible
        editable
      }
      senderEmailAddress {
        visible
        editable
      }
      senderGLN {
        visible
        editable
      }
      senderIBAN {
        visible
        editable
      }
      senderName {
        visible
        editable
      }
      senderNote {
        visible
        editable
      }
      senderReference {
        visible
        editable
      }
      senderTerms {
        visible
        editable
      }
      senderVatId {
        visible
        editable
      }
      supplierAddress1 {
        visible
        editable
      }
      supplierAddress2 {
        visible
        editable
      }
      supplierBBAN {
        visible
        editable
      }
      supplierCity {
        visible
        editable
      }
      supplierCountryCode {
        visible
        editable
      }
      supplierDescription {
        visible
        editable
      }
      supplierEmailAddress {
        visible
        editable
      }
      supplierGLN {
        visible
        editable
      }
      supplierIBAN {
        visible
        editable
      }
      supplierKVK {
        visible
        editable
      }
      supplierName {
        visible
        editable
      }
      supplierNetServiceId {
        visible
        editable
      }
      supplierCode {
        visible
        editable
      }
      supplierRecognitionInput {
        visible
        editable
      }
      supplierOrdernumber {
        visible
        editable
      }
      supplierPostalCode {
        visible
        editable
      }
      supplierVatId {
        visible
        editable
      }
      swiftCode {
        visible
        editable
      }
      title {
        visible
        editable
      }
      vatRate1 {
        visible
        editable
      }
      vatRate2 {
        visible
        editable
      }
      vatRate3 {
        visible
        editable
      }
    }
  }
`;
export const createFieldConfiguration = /* GraphQL */ `
  mutation CreateFieldConfiguration(
    $input: CreateFieldConfigurationInput!
    $condition: ModelFieldConfigurationConditionInput
  ) {
    createFieldConfiguration(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      documentTypeConcat
      documentType
      documentSubType
      fields {
        field
        editable
        length
      }
    }
  }
`;
export const updateFieldConfiguration = /* GraphQL */ `
  mutation UpdateFieldConfiguration(
    $input: UpdateFieldConfigurationInput!
    $condition: ModelFieldConfigurationConditionInput
  ) {
    updateFieldConfiguration(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      documentTypeConcat
      documentType
      documentSubType
      fields {
        field
        editable
        length
      }
    }
  }
`;
export const deleteFieldConfiguration = /* GraphQL */ `
  mutation DeleteFieldConfiguration(
    $input: DeleteFieldConfigurationInput!
    $condition: ModelFieldConfigurationConditionInput
  ) {
    deleteFieldConfiguration(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      documentTypeConcat
      documentType
      documentSubType
      fields {
        field
        editable
        length
      }
    }
  }
`;
export const createXMLTypeConfiguration = /* GraphQL */ `
  mutation CreateXMLTypeConfiguration(
    $input: CreateXMLTypeConfigurationInput!
    $condition: ModelXMLTypeConfigurationConditionInput
  ) {
    createXMLTypeConfiguration(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      xmlType
      customerID
      xPath
      imageXpath
      attachmentXpath
      nameSpace
      xsltFileName
      documentType
      documentSubType
    }
  }
`;
export const updateXMLTypeConfiguration = /* GraphQL */ `
  mutation UpdateXMLTypeConfiguration(
    $input: UpdateXMLTypeConfigurationInput!
    $condition: ModelXMLTypeConfigurationConditionInput
  ) {
    updateXMLTypeConfiguration(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      xmlType
      customerID
      xPath
      imageXpath
      attachmentXpath
      nameSpace
      xsltFileName
      documentType
      documentSubType
    }
  }
`;
export const deleteXMLTypeConfiguration = /* GraphQL */ `
  mutation DeleteXMLTypeConfiguration(
    $input: DeleteXMLTypeConfigurationInput!
    $condition: ModelXMLTypeConfigurationConditionInput
  ) {
    deleteXMLTypeConfiguration(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      xmlType
      customerID
      xPath
      imageXpath
      attachmentXpath
      nameSpace
      xsltFileName
      documentType
      documentSubType
    }
  }
`;
export const createRMProcessSelection = /* GraphQL */ `
  mutation CreateRMProcessSelection(
    $input: CreateRMProcessSelectionInput!
    $condition: ModelRMProcessSelectionConditionInput
  ) {
    createRMProcessSelection(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      customerID
      processID
      name
      processSelection
      allowedExtension
    }
  }
`;
export const updateRMProcessSelection = /* GraphQL */ `
  mutation UpdateRMProcessSelection(
    $input: UpdateRMProcessSelectionInput!
    $condition: ModelRMProcessSelectionConditionInput
  ) {
    updateRMProcessSelection(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      customerID
      processID
      name
      processSelection
      allowedExtension
    }
  }
`;
export const deleteRMProcessSelection = /* GraphQL */ `
  mutation DeleteRMProcessSelection(
    $input: DeleteRMProcessSelectionInput!
    $condition: ModelRMProcessSelectionConditionInput
  ) {
    deleteRMProcessSelection(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      customerID
      processID
      name
      processSelection
      allowedExtension
    }
  }
`;
export const createRelations = /* GraphQL */ `
  mutation CreateRelations(
    $input: CreateRelationsInput!
    $condition: ModelRelationsConditionInput
  ) {
    createRelations(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      isCreditor
      isDebtor
      companyCode
      code
      name
      vatNumber
      exportType
      sendMethod
      opCo
      interCompany
      language
      subType
      group
      profile
      riskProfile
      emailAddress
      phoneNumber
      street1
      street2
      postalCode
      city
      countryCode
      relationIdentifiers {
        code
        value
      }
      relationAdditionalDatas {
        name
        value
      }
    }
  }
`;
export const updateRelations = /* GraphQL */ `
  mutation UpdateRelations(
    $input: UpdateRelationsInput!
    $condition: ModelRelationsConditionInput
  ) {
    updateRelations(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      isCreditor
      isDebtor
      companyCode
      code
      name
      vatNumber
      exportType
      sendMethod
      opCo
      interCompany
      language
      subType
      group
      profile
      riskProfile
      emailAddress
      phoneNumber
      street1
      street2
      postalCode
      city
      countryCode
      relationIdentifiers {
        code
        value
      }
      relationAdditionalDatas {
        name
        value
      }
    }
  }
`;
export const deleteRelations = /* GraphQL */ `
  mutation DeleteRelations(
    $input: DeleteRelationsInput!
    $condition: ModelRelationsConditionInput
  ) {
    deleteRelations(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerID
      isCreditor
      isDebtor
      companyCode
      code
      name
      vatNumber
      exportType
      sendMethod
      opCo
      interCompany
      language
      subType
      group
      profile
      riskProfile
      emailAddress
      phoneNumber
      street1
      street2
      postalCode
      city
      countryCode
      relationIdentifiers {
        code
        value
      }
      relationAdditionalDatas {
        name
        value
      }
    }
  }
`;
export const createUserRights = /* GraphQL */ `
  mutation CreateUserRights(
    $input: CreateUserRightsInput!
    $condition: ModelUserRightsConditionInput
  ) {
    createUserRights(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      customerID
      rights {
        company
        roles
      }
    }
  }
`;
export const updateUserRights = /* GraphQL */ `
  mutation UpdateUserRights(
    $input: UpdateUserRightsInput!
    $condition: ModelUserRightsConditionInput
  ) {
    updateUserRights(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      customerID
      rights {
        company
        roles
      }
    }
  }
`;
export const deleteUserRights = /* GraphQL */ `
  mutation DeleteUserRights(
    $input: DeleteUserRightsInput!
    $condition: ModelUserRightsConditionInput
  ) {
    deleteUserRights(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      customerID
      rights {
        company
        roles
      }
    }
  }
`;
export const createUserRoles = /* GraphQL */ `
  mutation CreateUserRoles(
    $input: CreateUserRolesInput!
    $condition: ModelUserRolesConditionInput
  ) {
    createUserRoles(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      name
      description
      color
    }
  }
`;
export const updateUserRoles = /* GraphQL */ `
  mutation UpdateUserRoles(
    $input: UpdateUserRolesInput!
    $condition: ModelUserRolesConditionInput
  ) {
    updateUserRoles(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      name
      description
      color
    }
  }
`;
export const deleteUserRoles = /* GraphQL */ `
  mutation DeleteUserRoles(
    $input: DeleteUserRolesInput!
    $condition: ModelUserRolesConditionInput
  ) {
    deleteUserRoles(input: $input, condition: $condition) {
      createdAt
      updatedAt
      id
      name
      description
      color
    }
  }
`;
export const createPeppolIdentifiers = /* GraphQL */ `
  mutation CreatePeppolIdentifiers(
    $input: CreatePeppolIdentifiersInput!
    $condition: ModelPeppolIdentifiersConditionInput
  ) {
    createPeppolIdentifiers(input: $input, condition: $condition) {
      endpoint
      customerID
      companyCode
      name
      countryCode
      documentIds
      createdAt
      updatedAt
    }
  }
`;
export const updatePeppolIdentifiers = /* GraphQL */ `
  mutation UpdatePeppolIdentifiers(
    $input: UpdatePeppolIdentifiersInput!
    $condition: ModelPeppolIdentifiersConditionInput
  ) {
    updatePeppolIdentifiers(input: $input, condition: $condition) {
      endpoint
      customerID
      companyCode
      name
      countryCode
      documentIds
      createdAt
      updatedAt
    }
  }
`;
export const deletePeppolIdentifiers = /* GraphQL */ `
  mutation DeletePeppolIdentifiers(
    $input: DeletePeppolIdentifiersInput!
    $condition: ModelPeppolIdentifiersConditionInput
  ) {
    deletePeppolIdentifiers(input: $input, condition: $condition) {
      endpoint
      customerID
      companyCode
      name
      countryCode
      documentIds
      createdAt
      updatedAt
    }
  }
`;
export const createDocumentIdentifiers = /* GraphQL */ `
  mutation CreateDocumentIdentifiers(
    $input: CreateDocumentIdentifiersInput!
    $condition: ModelDocumentIdentifiersConditionInput
  ) {
    createDocumentIdentifiers(input: $input, condition: $condition) {
      id
      commonName
      documentIdentifier
      createdAt
      updatedAt
    }
  }
`;
export const updateDocumentIdentifiers = /* GraphQL */ `
  mutation UpdateDocumentIdentifiers(
    $input: UpdateDocumentIdentifiersInput!
    $condition: ModelDocumentIdentifiersConditionInput
  ) {
    updateDocumentIdentifiers(input: $input, condition: $condition) {
      id
      commonName
      documentIdentifier
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocumentIdentifiers = /* GraphQL */ `
  mutation DeleteDocumentIdentifiers(
    $input: DeleteDocumentIdentifiersInput!
    $condition: ModelDocumentIdentifiersConditionInput
  ) {
    deleteDocumentIdentifiers(input: $input, condition: $condition) {
      id
      commonName
      documentIdentifier
      createdAt
      updatedAt
    }
  }
`;
