/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      createdAt
      updatedAt
      id
      name
      netsuiteID
      services {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              id
              name
            }
          }
        }
      }
      ssoDomain
      selfValidation
      defaultRoles
      customerOf
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        id
        name
        netsuiteID
        services {
          id
          name
          children {
            id
            name
            children {
              id
              name
            }
          }
        }
        ssoDomain
        selfValidation
        defaultRoles
        customerOf
      }
      nextToken
    }
  }
`;
export const getCustomerReport = /* GraphQL */ `
  query GetCustomerReport($id: ID!) {
    getCustomerReport(id: $id) {
      id
      createdAt
      updatedAt
      tableauCustomerGuid
      tableauUser
      reports {
        name
        url
      }
    }
  }
`;
export const listCustomerReports = /* GraphQL */ `
  query ListCustomerReports(
    $filter: ModelCustomerReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        tableauCustomerGuid
        tableauUser
        reports {
          name
          url
        }
      }
      nextToken
    }
  }
`;
export const getCompanyData = /* GraphQL */ `
  query GetCompanyData($customerID: String!, $id: ID!) {
    getCompanyData(customerID: $customerID, id: $id) {
      id
      createdAt
      updatedAt
      customerID
      name
      companyCode
      businessUnit
      gln
      coc
      vatID
      address
      city
      postalCode
      opCo
      emailAddress
      businessArea
      synonyms {
        operator
        value
      }
      exportType
      addressLine2
      country
      IBAN
      BIC
      termsAndConditions
      isoCode
      active
      default
    }
  }
`;
export const listCompanyData = /* GraphQL */ `
  query ListCompanyData(
    $customerID: String
    $id: ModelIDKeyConditionInput
    $filter: ModelCompanyDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyData(
      customerID: $customerID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
        customerID
        name
        companyCode
        businessUnit
        gln
        coc
        vatID
        address
        city
        postalCode
        opCo
        emailAddress
        businessArea
        synonyms {
          operator
          value
        }
        exportType
        addressLine2
        country
        IBAN
        BIC
        termsAndConditions
        isoCode
        active
        default
      }
      nextToken
    }
  }
`;
export const getDomainSetting = /* GraphQL */ `
  query GetDomainSetting($customerID: String!, $domain: String!) {
    getDomainSetting(customerID: $customerID, domain: $domain) {
      id
      createdAt
      updatedAt
      customerID
      denied
      domain
      ignoredFiles
      fileTypeProcess {
        process
        friendlyProcessName
        filePattern
      }
      excluded {
        type
        search
      }
    }
  }
`;
export const listDomainSettings = /* GraphQL */ `
  query ListDomainSettings(
    $customerID: String
    $domain: ModelStringKeyConditionInput
    $filter: ModelDomainSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDomainSettings(
      customerID: $customerID
      domain: $domain
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
        customerID
        denied
        domain
        ignoredFiles
        fileTypeProcess {
          process
          friendlyProcessName
          filePattern
        }
        excluded {
          type
          search
        }
      }
      nextToken
    }
  }
`;
export const getBamNumberRecognition = /* GraphQL */ `
  query GetBamNumberRecognition($id: ID!) {
    getBamNumberRecognition(id: $id) {
      id
      createdAt
      updatedAt
      customerID
      companyCode
      fieldType
      comparison
      value
    }
  }
`;
export const listBamNumberRecognitions = /* GraphQL */ `
  query ListBamNumberRecognitions(
    $filter: ModelBamNumberRecognitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBamNumberRecognitions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customerID
        companyCode
        fieldType
        comparison
        value
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      icGuid
      createdAt
      createdEpoch
      updatedAt
      amountGross
      amountNet1
      amountNet2
      amountNet3
      amountNetTotal
      amountVat1
      amountVat2
      amountVat3
      amountVatTotal
      appliedMapping
      bankName
      btBUMID
      btInvoiceRef
      btOrigin
      btReceiverRef
      btScanDate
      businessArea
      businessUnit
      companyID
      companyCode
      companyName
      companyNameOverride
      currency
      customerID
      deliveryDate
      deliveryTerms
      deliveryEndDate
      deliveryLocation
      deliveryInstruction
      documentNumber
      documentSubType
      documentType
      dueDate
      exportApproved
      exportMapping
      exportType
      externalSystem
      externalSystemID
      forceValid
      gAccount
      gAmount
      invoiceDate
      invoiceNumber
      invoiceType
      integrationDetail
      ivsProcessID
      lastError
      noteNumber
      noteToSupplier
      opCo
      owner
      orderDate
      orderType
      paymentTerms
      pdfUploadedFrontEnd
      purchasingCategory
      poCreator
      poOwner
      purpose
      receivedMessageID
      receiverAddress1
      receiverAddress2
      receiverCity
      receiverCompanyName
      receiverCompanyCode
      receiverContractNumber
      receiverCountryCode
      receiverContactName
      receiverContactFirstName
      receiverContactPhoneNumber
      receiverContactEmailAddress
      receiverGLN
      receiverKVK
      receiverNetServiceId
      receiverOrdernumber
      receiverPostalCode
      receiverVatId
      receiverEmailAddress
      receiverEndPointIsoCode
      receiverEndPointID
      remark
      returnDate
      returnEmailAddress
      returnStatus
      s3ResourceAttachment {
        s3Resource
      }
      s3ResourceWebserviceResponse
      s3ResourceExportedDocument
      s3ResourceGeneratedImage
      s3ResourceOrgImage
      s3ResourceOrgXML
      source
      status
      secondaryStatus
      senderAddress1
      senderAddress2
      senderCompanyCode
      senderContractNumber
      senderCountryCode
      senderContactName
      senderContactFirstName
      senderContactPhoneNumber
      senderContactEmailAddress
      senderDocCode
      senderDocNum
      senderEmailAddress
      senderGLN
      senderIBAN
      senderName
      senderNote
      senderReference
      senderTerms
      senderPostalCode
      senderVatId
      subscriptionType
      supplierAddress1
      supplierAddress2
      supplierBBAN
      supplierCity
      supplierCountryCode
      supplierDescription
      supplierEmailAddress
      supplierGLN
      supplierIBAN
      supplierKVK
      supplierName
      supplierNetServiceId
      supplierCode
      supplierRecognitionInput
      supplierOrdernumber
      supplierPostalCode
      supplierVatId
      swiftCode
      vatRate1
      vatRate2
      vatRate3
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      lines {
        id
        lineNumber
        lineNetTotal
        lineVatRate
        lineVatAmount
        lineUnitPrice
        lineQuantity
        lineDescription
        lineUoM
        lineArticleCode
        lineSupplierArticleCode
        lineOrderNumber
        lineDiscountRate
        lineDiscountAmount
        lineDiscountName
        lineFreeNumber1
        lineFreeNumber2
        lineFreeNumber3
        lineFreeText1
        lineFreeText2
        lineFreeText3
      }
      locked
      lockedBy
      lockedSince
      hasBeenReProcessed
      sendScanReportToBasware
      scanReportCode
      scanReportDescription
      scanReportComment
      title
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        icGuid
        createdAt
        createdEpoch
        updatedAt
        amountGross
        amountNet1
        amountNet2
        amountNet3
        amountNetTotal
        amountVat1
        amountVat2
        amountVat3
        amountVatTotal
        appliedMapping
        bankName
        btBUMID
        btInvoiceRef
        btOrigin
        btReceiverRef
        btScanDate
        businessArea
        businessUnit
        companyID
        companyCode
        companyName
        companyNameOverride
        currency
        customerID
        deliveryDate
        deliveryTerms
        deliveryEndDate
        deliveryLocation
        deliveryInstruction
        documentNumber
        documentSubType
        documentType
        dueDate
        exportApproved
        exportMapping
        exportType
        externalSystem
        externalSystemID
        forceValid
        gAccount
        gAmount
        invoiceDate
        invoiceNumber
        invoiceType
        integrationDetail
        ivsProcessID
        lastError
        noteNumber
        noteToSupplier
        opCo
        owner
        orderDate
        orderType
        paymentTerms
        pdfUploadedFrontEnd
        purchasingCategory
        poCreator
        poOwner
        purpose
        receivedMessageID
        receiverAddress1
        receiverAddress2
        receiverCity
        receiverCompanyName
        receiverCompanyCode
        receiverContractNumber
        receiverCountryCode
        receiverContactName
        receiverContactFirstName
        receiverContactPhoneNumber
        receiverContactEmailAddress
        receiverGLN
        receiverKVK
        receiverNetServiceId
        receiverOrdernumber
        receiverPostalCode
        receiverVatId
        receiverEmailAddress
        receiverEndPointIsoCode
        receiverEndPointID
        remark
        returnDate
        returnEmailAddress
        returnStatus
        s3ResourceAttachment {
          s3Resource
        }
        s3ResourceWebserviceResponse
        s3ResourceExportedDocument
        s3ResourceGeneratedImage
        s3ResourceOrgImage
        s3ResourceOrgXML
        source
        status
        secondaryStatus
        senderAddress1
        senderAddress2
        senderCompanyCode
        senderContractNumber
        senderCountryCode
        senderContactName
        senderContactFirstName
        senderContactPhoneNumber
        senderContactEmailAddress
        senderDocCode
        senderDocNum
        senderEmailAddress
        senderGLN
        senderIBAN
        senderName
        senderNote
        senderReference
        senderTerms
        senderPostalCode
        senderVatId
        subscriptionType
        supplierAddress1
        supplierAddress2
        supplierBBAN
        supplierCity
        supplierCountryCode
        supplierDescription
        supplierEmailAddress
        supplierGLN
        supplierIBAN
        supplierKVK
        supplierName
        supplierNetServiceId
        supplierCode
        supplierRecognitionInput
        supplierOrdernumber
        supplierPostalCode
        supplierVatId
        swiftCode
        vatRate1
        vatRate2
        vatRate3
        history {
          id
          createdAt
          user
          description
          type
          documentChanges {
            field
            oldValue
            newValue
          }
        }
        lines {
          id
          lineNumber
          lineNetTotal
          lineVatRate
          lineVatAmount
          lineUnitPrice
          lineQuantity
          lineDescription
          lineUoM
          lineArticleCode
          lineSupplierArticleCode
          lineOrderNumber
          lineDiscountRate
          lineDiscountAmount
          lineDiscountName
          lineFreeNumber1
          lineFreeNumber2
          lineFreeNumber3
          lineFreeText1
          lineFreeText2
          lineFreeText3
        }
        locked
        lockedBy
        lockedSince
        hasBeenReProcessed
        sendScanReportToBasware
        scanReportCode
        scanReportDescription
        scanReportComment
        title
      }
      nextToken
    }
  }
`;
export const getCrudHistory = /* GraphQL */ `
  query GetCrudHistory($id: ID!) {
    getCrudHistory(id: $id) {
      id
      createdAt
      updatedAt
      customerID
      change
      section
      tableLineID
      name
      user
      changes {
        field
        oldValue
        newValue
      }
    }
  }
`;
export const listCrudHistories = /* GraphQL */ `
  query ListCrudHistories(
    $filter: ModelCrudHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrudHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        customerID
        change
        section
        tableLineID
        name
        user
        changes {
          field
          oldValue
          newValue
        }
      }
      nextToken
    }
  }
`;
export const getRecognitionDocument = /* GraphQL */ `
  query GetRecognitionDocument($id: ID!) {
    getRecognitionDocument(id: $id) {
      id
      icGuid
      customerID
      createdAt
      createdEpoch
      advanced
      updatedAt
      documentSubType
      companyCode
      documentType
      integrationDetail
      lastError
      status
      secondaryStatus
      ocrStatus
      validator
      receivedMessageID
      bodyS3Resource
      s3ResourceOrgImage
      s3ResourceAttachment {
        s3Resource
      }
      locked
      lockedBy
      lockedSince
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      remark
      exportType
      supplierIBAN {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      gAccount {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      supplierVatId {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      supplierCoc {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      credit {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      invoiceNumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      dueDate {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      invoiceDate {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      receiverOrdernumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      receiverContractNumber {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      currency {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate1 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate2 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNet3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVat3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      vatRate3 {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountNetTotal {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountVatTotal {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
      amountGross {
        value
        originalText
        ocrConfidence
        confidence
        page
        width
        height
        left
        top
      }
    }
  }
`;
export const listRecognitionDocuments = /* GraphQL */ `
  query ListRecognitionDocuments(
    $filter: ModelRecognitionDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecognitionDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        icGuid
        customerID
        createdAt
        createdEpoch
        advanced
        updatedAt
        documentSubType
        companyCode
        documentType
        integrationDetail
        lastError
        status
        secondaryStatus
        ocrStatus
        validator
        receivedMessageID
        bodyS3Resource
        s3ResourceOrgImage
        s3ResourceAttachment {
          s3Resource
        }
        locked
        lockedBy
        lockedSince
        history {
          id
          createdAt
          user
          description
          type
          documentChanges {
            field
            oldValue
            newValue
          }
        }
        remark
        exportType
        supplierIBAN {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        gAccount {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        supplierVatId {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        supplierCoc {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        credit {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        invoiceNumber {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        dueDate {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        invoiceDate {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        receiverOrdernumber {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        receiverContractNumber {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        currency {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountNet1 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountVat1 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        vatRate1 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountNet2 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountVat2 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        vatRate2 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountNet3 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountVat3 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        vatRate3 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountNetTotal {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountVatTotal {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountGross {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
      }
      nextToken
    }
  }
`;
export const getReturnServiceEmailTemplates = /* GraphQL */ `
  query GetReturnServiceEmailTemplates($customerID: String!, $id: ID!) {
    getReturnServiceEmailTemplates(customerID: $customerID, id: $id) {
      id
      createdAt
      updatedAt
      customerID
      templateName
      senderAddress
      replyToAddress
      to
      cc
      bcc
      subject
      htmlBody
      automatic
    }
  }
`;
export const listReturnServiceEmailTemplates = /* GraphQL */ `
  query ListReturnServiceEmailTemplates(
    $customerID: String
    $id: ModelIDKeyConditionInput
    $filter: ModelReturnServiceEmailTemplatesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReturnServiceEmailTemplates(
      customerID: $customerID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
        customerID
        templateName
        senderAddress
        replyToAddress
        to
        cc
        bcc
        subject
        htmlBody
        automatic
      }
      nextToken
    }
  }
`;
export const getGLNSupplier = /* GraphQL */ `
  query GetGLNSupplier($id: ID!) {
    getGLNSupplier(id: $id) {
      id
      createdAt
      updatedAt
      customerID
      opCo
      companyCode
      supplierCode
      supplierName
      glnNumbers
    }
  }
`;
export const listGLNSuppliers = /* GraphQL */ `
  query ListGLNSuppliers(
    $filter: ModelGLNSupplierFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGLNSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        customerID
        opCo
        companyCode
        supplierCode
        supplierName
        glnNumbers
      }
      nextToken
    }
  }
`;
export const getReceivedMessages = /* GraphQL */ `
  query GetReceivedMessages($id: ID!) {
    getReceivedMessages(id: $id) {
      id
      createdAt
      updatedAt
      customerID
      attribute1
      attribute2
      attribute3
      attribute4
      attribute5
      bodyS3Resource
      domain
      errorText
      hasIgnoredFiles
      ignoreds3ResourceAttachment {
        s3Resource
      }
      s3ResourceAttachment {
        s3Resource
      }
      secondaryStatus
      senderAddress
      status
      subject
      companyCode
      integrationID
      recipientAddress
      forceValid
      processID
      processName
      history {
        id
        createdAt
        user
        description
        type
        documentChanges {
          field
          oldValue
          newValue
        }
      }
      test
    }
  }
`;
export const listReceivedMessages = /* GraphQL */ `
  query ListReceivedMessages(
    $filter: ModelReceivedMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceivedMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customerID
        attribute1
        attribute2
        attribute3
        attribute4
        attribute5
        bodyS3Resource
        domain
        errorText
        hasIgnoredFiles
        ignoreds3ResourceAttachment {
          s3Resource
        }
        s3ResourceAttachment {
          s3Resource
        }
        secondaryStatus
        senderAddress
        status
        subject
        companyCode
        integrationID
        recipientAddress
        forceValid
        processID
        processName
        history {
          id
          createdAt
          user
          description
          type
          documentChanges {
            field
            oldValue
            newValue
          }
        }
        test
      }
      nextToken
    }
  }
`;
export const getFieldActions = /* GraphQL */ `
  query GetFieldActions($customerID: String!, $id: ID!) {
    getFieldActions(customerID: $customerID, id: $id) {
      id
      filter
      type
      createdAt
      updatedAt
      customerID
      fieldName
      fieldLocation
      input
      value
      raiseOnError
      errorText
      stepIndex
      beforeProcess
      afterProcess
      method
      documentType
      documentSubType
      description
      integrationID
      requestUrl
      requestHeaders
    }
  }
`;
export const listFieldActions = /* GraphQL */ `
  query ListFieldActions(
    $customerID: String
    $id: ModelIDKeyConditionInput
    $filter: ModelFieldActionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFieldActions(
      customerID: $customerID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        filter
        type
        createdAt
        updatedAt
        customerID
        fieldName
        fieldLocation
        input
        value
        raiseOnError
        errorText
        stepIndex
        beforeProcess
        afterProcess
        method
        documentType
        documentSubType
        description
        integrationID
        requestUrl
        requestHeaders
      }
      nextToken
    }
  }
`;
export const getCompanyRecognitionOutcome = /* GraphQL */ `
  query GetCompanyRecognitionOutcome(
    $customerID: String!
    $documentID: String!
  ) {
    getCompanyRecognitionOutcome(
      customerID: $customerID
      documentID: $documentID
    ) {
      id
      customerID
      documentID
      success
      matchedCompanies {
        companyID
        synonym
        token
        ratio
        lengthRatio
      }
      recognizedCompanyID
      recognizedSynonym
      recognizedToken
      recognizedCertaintyScore
      textractUsed
      quickRatio
      tokensFromInvoice
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyRecognitionOutcomes = /* GraphQL */ `
  query ListCompanyRecognitionOutcomes(
    $customerID: String
    $documentID: ModelStringKeyConditionInput
    $filter: ModelCompanyRecognitionOutcomeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyRecognitionOutcomes(
      customerID: $customerID
      documentID: $documentID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        customerID
        documentID
        success
        matchedCompanies {
          companyID
          synonym
          token
          ratio
          lengthRatio
        }
        recognizedCompanyID
        recognizedSynonym
        recognizedToken
        recognizedCertaintyScore
        textractUsed
        quickRatio
        tokensFromInvoice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocumentConfiguration = /* GraphQL */ `
  query GetDocumentConfiguration($id: ID!, $documentType: String!) {
    getDocumentConfiguration(id: $id, documentType: $documentType) {
      id
      createdAt
      updatedAt
      documentType
      amountGross {
        visible
        editable
      }
      amountNet1 {
        visible
        editable
      }
      amountNet2 {
        visible
        editable
      }
      amountNet3 {
        visible
        editable
      }
      amountNetTotal {
        visible
        editable
      }
      amountVat1 {
        visible
        editable
      }
      amountVat2 {
        visible
        editable
      }
      amountVat3 {
        visible
        editable
      }
      amountVatTotal {
        visible
        editable
      }
      bankName {
        visible
        editable
      }
      businessArea {
        visible
        editable
      }
      businessUnit {
        visible
        editable
      }
      company {
        visible
        editable
      }
      companyNameOverride {
        visible
        editable
      }
      currency {
        visible
        editable
      }
      deliveryDate {
        visible
        editable
      }
      deliveryEndDate {
        visible
        editable
      }
      deliveryLocation {
        visible
        editable
      }
      deliveryInstruction {
        visible
        editable
      }
      deliveryTerms {
        visible
        editable
      }
      documentNumber {
        visible
        editable
      }
      dueDate {
        visible
        editable
      }
      forceValid {
        visible
        editable
      }
      gAmount {
        visible
        editable
      }
      gAccount {
        visible
        editable
      }
      invoiceDate {
        visible
        editable
      }
      invoiceNumber {
        visible
        editable
      }
      invoiceType {
        visible
        editable
      }
      noteNumber {
        visible
        editable
      }
      noteToSupplier {
        visible
        editable
      }
      opCo {
        visible
        editable
      }
      orderDate {
        visible
        editable
      }
      orderType {
        visible
        editable
      }
      paymentTerms {
        visible
        editable
      }
      poCreator {
        visible
        editable
      }
      poOwner {
        visible
        editable
      }
      purchasingCategory {
        visible
        editable
      }
      purpose {
        visible
        editable
      }
      receiverAddress1 {
        visible
        editable
      }
      receiverAddress2 {
        visible
        editable
      }
      receiverCity {
        visible
        editable
      }
      receiverCompanyName {
        visible
        editable
      }
      receiverCompanyCode {
        visible
        editable
      }
      receiverContractNumber {
        visible
        editable
      }
      receiverCountryCode {
        visible
        editable
      }
      receiverGLN {
        visible
        editable
      }
      receiverKVK {
        visible
        editable
      }
      receiverNetServiceId {
        visible
        editable
      }
      receiverOrdernumber {
        visible
        editable
      }
      receiverPostalCode {
        visible
        editable
      }
      receiverVatId {
        visible
        editable
      }
      receiverEmailAddress {
        visible
        editable
      }
      senderAddress1 {
        visible
        editable
      }
      senderAddress2 {
        visible
        editable
      }
      senderCompanyCode {
        visible
        editable
      }
      senderContractNumber {
        visible
        editable
      }
      senderCountryCode {
        visible
        editable
      }
      senderEmailAddress {
        visible
        editable
      }
      senderGLN {
        visible
        editable
      }
      senderIBAN {
        visible
        editable
      }
      senderName {
        visible
        editable
      }
      senderNote {
        visible
        editable
      }
      senderReference {
        visible
        editable
      }
      senderTerms {
        visible
        editable
      }
      senderVatId {
        visible
        editable
      }
      supplierAddress1 {
        visible
        editable
      }
      supplierAddress2 {
        visible
        editable
      }
      supplierBBAN {
        visible
        editable
      }
      supplierCity {
        visible
        editable
      }
      supplierCountryCode {
        visible
        editable
      }
      supplierDescription {
        visible
        editable
      }
      supplierEmailAddress {
        visible
        editable
      }
      supplierGLN {
        visible
        editable
      }
      supplierIBAN {
        visible
        editable
      }
      supplierKVK {
        visible
        editable
      }
      supplierName {
        visible
        editable
      }
      supplierNetServiceId {
        visible
        editable
      }
      supplierCode {
        visible
        editable
      }
      supplierRecognitionInput {
        visible
        editable
      }
      supplierOrdernumber {
        visible
        editable
      }
      supplierPostalCode {
        visible
        editable
      }
      supplierVatId {
        visible
        editable
      }
      swiftCode {
        visible
        editable
      }
      title {
        visible
        editable
      }
      vatRate1 {
        visible
        editable
      }
      vatRate2 {
        visible
        editable
      }
      vatRate3 {
        visible
        editable
      }
    }
  }
`;
export const listDocumentConfigurations = /* GraphQL */ `
  query ListDocumentConfigurations(
    $id: ID
    $documentType: ModelStringKeyConditionInput
    $filter: ModelDocumentConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocumentConfigurations(
      id: $id
      documentType: $documentType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
        documentType
        amountGross {
          visible
          editable
        }
        amountNet1 {
          visible
          editable
        }
        amountNet2 {
          visible
          editable
        }
        amountNet3 {
          visible
          editable
        }
        amountNetTotal {
          visible
          editable
        }
        amountVat1 {
          visible
          editable
        }
        amountVat2 {
          visible
          editable
        }
        amountVat3 {
          visible
          editable
        }
        amountVatTotal {
          visible
          editable
        }
        bankName {
          visible
          editable
        }
        businessArea {
          visible
          editable
        }
        businessUnit {
          visible
          editable
        }
        company {
          visible
          editable
        }
        companyNameOverride {
          visible
          editable
        }
        currency {
          visible
          editable
        }
        deliveryDate {
          visible
          editable
        }
        deliveryEndDate {
          visible
          editable
        }
        deliveryLocation {
          visible
          editable
        }
        deliveryInstruction {
          visible
          editable
        }
        deliveryTerms {
          visible
          editable
        }
        documentNumber {
          visible
          editable
        }
        dueDate {
          visible
          editable
        }
        forceValid {
          visible
          editable
        }
        gAmount {
          visible
          editable
        }
        gAccount {
          visible
          editable
        }
        invoiceDate {
          visible
          editable
        }
        invoiceNumber {
          visible
          editable
        }
        invoiceType {
          visible
          editable
        }
        noteNumber {
          visible
          editable
        }
        noteToSupplier {
          visible
          editable
        }
        opCo {
          visible
          editable
        }
        orderDate {
          visible
          editable
        }
        orderType {
          visible
          editable
        }
        paymentTerms {
          visible
          editable
        }
        poCreator {
          visible
          editable
        }
        poOwner {
          visible
          editable
        }
        purchasingCategory {
          visible
          editable
        }
        purpose {
          visible
          editable
        }
        receiverAddress1 {
          visible
          editable
        }
        receiverAddress2 {
          visible
          editable
        }
        receiverCity {
          visible
          editable
        }
        receiverCompanyName {
          visible
          editable
        }
        receiverCompanyCode {
          visible
          editable
        }
        receiverContractNumber {
          visible
          editable
        }
        receiverCountryCode {
          visible
          editable
        }
        receiverGLN {
          visible
          editable
        }
        receiverKVK {
          visible
          editable
        }
        receiverNetServiceId {
          visible
          editable
        }
        receiverOrdernumber {
          visible
          editable
        }
        receiverPostalCode {
          visible
          editable
        }
        receiverVatId {
          visible
          editable
        }
        receiverEmailAddress {
          visible
          editable
        }
        senderAddress1 {
          visible
          editable
        }
        senderAddress2 {
          visible
          editable
        }
        senderCompanyCode {
          visible
          editable
        }
        senderContractNumber {
          visible
          editable
        }
        senderCountryCode {
          visible
          editable
        }
        senderEmailAddress {
          visible
          editable
        }
        senderGLN {
          visible
          editable
        }
        senderIBAN {
          visible
          editable
        }
        senderName {
          visible
          editable
        }
        senderNote {
          visible
          editable
        }
        senderReference {
          visible
          editable
        }
        senderTerms {
          visible
          editable
        }
        senderVatId {
          visible
          editable
        }
        supplierAddress1 {
          visible
          editable
        }
        supplierAddress2 {
          visible
          editable
        }
        supplierBBAN {
          visible
          editable
        }
        supplierCity {
          visible
          editable
        }
        supplierCountryCode {
          visible
          editable
        }
        supplierDescription {
          visible
          editable
        }
        supplierEmailAddress {
          visible
          editable
        }
        supplierGLN {
          visible
          editable
        }
        supplierIBAN {
          visible
          editable
        }
        supplierKVK {
          visible
          editable
        }
        supplierName {
          visible
          editable
        }
        supplierNetServiceId {
          visible
          editable
        }
        supplierCode {
          visible
          editable
        }
        supplierRecognitionInput {
          visible
          editable
        }
        supplierOrdernumber {
          visible
          editable
        }
        supplierPostalCode {
          visible
          editable
        }
        supplierVatId {
          visible
          editable
        }
        swiftCode {
          visible
          editable
        }
        title {
          visible
          editable
        }
        vatRate1 {
          visible
          editable
        }
        vatRate2 {
          visible
          editable
        }
        vatRate3 {
          visible
          editable
        }
      }
      nextToken
    }
  }
`;
export const getFieldConfiguration = /* GraphQL */ `
  query GetFieldConfiguration($id: ID!, $documentTypeConcat: String!) {
    getFieldConfiguration(id: $id, documentTypeConcat: $documentTypeConcat) {
      id
      createdAt
      updatedAt
      documentTypeConcat
      documentType
      documentSubType
      fields {
        field
        editable
        length
      }
    }
  }
`;
export const listFieldConfigurations = /* GraphQL */ `
  query ListFieldConfigurations(
    $id: ID
    $documentTypeConcat: ModelStringKeyConditionInput
    $filter: ModelFieldConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFieldConfigurations(
      id: $id
      documentTypeConcat: $documentTypeConcat
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
        documentTypeConcat
        documentType
        documentSubType
        fields {
          field
          editable
          length
        }
      }
      nextToken
    }
  }
`;
export const getXMLTypeConfiguration = /* GraphQL */ `
  query GetXMLTypeConfiguration($id: ID!, $customerID: String!) {
    getXMLTypeConfiguration(id: $id, customerID: $customerID) {
      createdAt
      updatedAt
      id
      xmlType
      customerID
      xPath
      imageXpath
      attachmentXpath
      nameSpace
      xsltFileName
      documentType
      documentSubType
    }
  }
`;
export const listXMLTypeConfigurations = /* GraphQL */ `
  query ListXMLTypeConfigurations(
    $id: ID
    $customerID: ModelStringKeyConditionInput
    $filter: ModelXMLTypeConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listXMLTypeConfigurations(
      id: $id
      customerID: $customerID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        updatedAt
        id
        xmlType
        customerID
        xPath
        imageXpath
        attachmentXpath
        nameSpace
        xsltFileName
        documentType
        documentSubType
      }
      nextToken
    }
  }
`;
export const getRMProcessSelection = /* GraphQL */ `
  query GetRMProcessSelection($customerID: String!, $id: ID!) {
    getRMProcessSelection(customerID: $customerID, id: $id) {
      createdAt
      updatedAt
      id
      customerID
      processID
      name
      processSelection
      allowedExtension
    }
  }
`;
export const listRMProcessSelections = /* GraphQL */ `
  query ListRMProcessSelections(
    $customerID: String
    $id: ModelIDKeyConditionInput
    $filter: ModelRMProcessSelectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRMProcessSelections(
      customerID: $customerID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        updatedAt
        id
        customerID
        processID
        name
        processSelection
        allowedExtension
      }
      nextToken
    }
  }
`;
export const getRelations = /* GraphQL */ `
  query GetRelations($customerID: String!, $id: ID!) {
    getRelations(customerID: $customerID, id: $id) {
      id
      createdAt
      updatedAt
      customerID
      isCreditor
      isDebtor
      companyCode
      code
      name
      vatNumber
      exportType
      sendMethod
      opCo
      interCompany
      language
      subType
      group
      profile
      riskProfile
      emailAddress
      phoneNumber
      street1
      street2
      postalCode
      city
      countryCode
      relationIdentifiers {
        code
        value
      }
      relationAdditionalDatas {
        name
        value
      }
    }
  }
`;
export const listRelations = /* GraphQL */ `
  query ListRelations(
    $customerID: String
    $id: ModelIDKeyConditionInput
    $filter: ModelRelationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRelations(
      customerID: $customerID
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
        customerID
        isCreditor
        isDebtor
        companyCode
        code
        name
        vatNumber
        exportType
        sendMethod
        opCo
        interCompany
        language
        subType
        group
        profile
        riskProfile
        emailAddress
        phoneNumber
        street1
        street2
        postalCode
        city
        countryCode
        relationIdentifiers {
          code
          value
        }
        relationAdditionalDatas {
          name
          value
        }
      }
      nextToken
    }
  }
`;
export const getUserRights = /* GraphQL */ `
  query GetUserRights($id: ID!) {
    getUserRights(id: $id) {
      createdAt
      updatedAt
      id
      customerID
      rights {
        company
        roles
      }
    }
  }
`;
export const listUserRights = /* GraphQL */ `
  query ListUserRights(
    $filter: ModelUserRightsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        id
        customerID
        rights {
          company
          roles
        }
      }
      nextToken
    }
  }
`;
export const getUserRoles = /* GraphQL */ `
  query GetUserRoles($id: ID!, $name: String!) {
    getUserRoles(id: $id, name: $name) {
      createdAt
      updatedAt
      id
      name
      description
      color
    }
  }
`;
export const listUserRoles = /* GraphQL */ `
  query ListUserRoles(
    $id: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelUserRolesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserRoles(
      id: $id
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        updatedAt
        id
        name
        description
        color
      }
      nextToken
    }
  }
`;
export const getPeppolIdentifiers = /* GraphQL */ `
  query GetPeppolIdentifiers($endpoint: ID!) {
    getPeppolIdentifiers(endpoint: $endpoint) {
      endpoint
      customerID
      companyCode
      name
      countryCode
      documentIds
      createdAt
      updatedAt
    }
  }
`;
export const listPeppolIdentifiers = /* GraphQL */ `
  query ListPeppolIdentifiers(
    $endpoint: ID
    $filter: ModelPeppolIdentifiersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPeppolIdentifiers(
      endpoint: $endpoint
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        endpoint
        customerID
        companyCode
        name
        countryCode
        documentIds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocumentIdentifiers = /* GraphQL */ `
  query GetDocumentIdentifiers($id: ID!) {
    getDocumentIdentifiers(id: $id) {
      id
      commonName
      documentIdentifier
      createdAt
      updatedAt
    }
  }
`;
export const listDocumentIdentifiers = /* GraphQL */ `
  query ListDocumentIdentifiers(
    $id: ID
    $filter: ModelDocumentIdentifiersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocumentIdentifiers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        commonName
        documentIdentifier
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customerIDCreatedAtIndex = /* GraphQL */ `
  query CustomerIDCreatedAtIndex(
    $customerID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerIDCreatedAtIndex(
      customerID: $customerID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        icGuid
        createdAt
        createdEpoch
        updatedAt
        amountGross
        amountNet1
        amountNet2
        amountNet3
        amountNetTotal
        amountVat1
        amountVat2
        amountVat3
        amountVatTotal
        appliedMapping
        bankName
        btBUMID
        btInvoiceRef
        btOrigin
        btReceiverRef
        btScanDate
        businessArea
        businessUnit
        companyID
        companyCode
        companyName
        companyNameOverride
        currency
        customerID
        deliveryDate
        deliveryTerms
        deliveryEndDate
        deliveryLocation
        deliveryInstruction
        documentNumber
        documentSubType
        documentType
        dueDate
        exportApproved
        exportMapping
        exportType
        externalSystem
        externalSystemID
        forceValid
        gAccount
        gAmount
        invoiceDate
        invoiceNumber
        invoiceType
        integrationDetail
        ivsProcessID
        lastError
        noteNumber
        noteToSupplier
        opCo
        owner
        orderDate
        orderType
        paymentTerms
        pdfUploadedFrontEnd
        purchasingCategory
        poCreator
        poOwner
        purpose
        receivedMessageID
        receiverAddress1
        receiverAddress2
        receiverCity
        receiverCompanyName
        receiverCompanyCode
        receiverContractNumber
        receiverCountryCode
        receiverContactName
        receiverContactFirstName
        receiverContactPhoneNumber
        receiverContactEmailAddress
        receiverGLN
        receiverKVK
        receiverNetServiceId
        receiverOrdernumber
        receiverPostalCode
        receiverVatId
        receiverEmailAddress
        receiverEndPointIsoCode
        receiverEndPointID
        remark
        returnDate
        returnEmailAddress
        returnStatus
        s3ResourceAttachment {
          s3Resource
        }
        s3ResourceWebserviceResponse
        s3ResourceExportedDocument
        s3ResourceGeneratedImage
        s3ResourceOrgImage
        s3ResourceOrgXML
        source
        status
        secondaryStatus
        senderAddress1
        senderAddress2
        senderCompanyCode
        senderContractNumber
        senderCountryCode
        senderContactName
        senderContactFirstName
        senderContactPhoneNumber
        senderContactEmailAddress
        senderDocCode
        senderDocNum
        senderEmailAddress
        senderGLN
        senderIBAN
        senderName
        senderNote
        senderReference
        senderTerms
        senderPostalCode
        senderVatId
        subscriptionType
        supplierAddress1
        supplierAddress2
        supplierBBAN
        supplierCity
        supplierCountryCode
        supplierDescription
        supplierEmailAddress
        supplierGLN
        supplierIBAN
        supplierKVK
        supplierName
        supplierNetServiceId
        supplierCode
        supplierRecognitionInput
        supplierOrdernumber
        supplierPostalCode
        supplierVatId
        swiftCode
        vatRate1
        vatRate2
        vatRate3
        history {
          id
          createdAt
          user
          description
          type
          documentChanges {
            field
            oldValue
            newValue
          }
        }
        lines {
          id
          lineNumber
          lineNetTotal
          lineVatRate
          lineVatAmount
          lineUnitPrice
          lineQuantity
          lineDescription
          lineUoM
          lineArticleCode
          lineSupplierArticleCode
          lineOrderNumber
          lineDiscountRate
          lineDiscountAmount
          lineDiscountName
          lineFreeNumber1
          lineFreeNumber2
          lineFreeNumber3
          lineFreeText1
          lineFreeText2
          lineFreeText3
        }
        locked
        lockedBy
        lockedSince
        hasBeenReProcessed
        sendScanReportToBasware
        scanReportCode
        scanReportDescription
        scanReportComment
        title
      }
      nextToken
    }
  }
`;
export const documentNumberCustomerIDIndex = /* GraphQL */ `
  query DocumentNumberCustomerIDIndex(
    $documentNumber: String!
    $customerID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentNumberCustomerIDIndex(
      documentNumber: $documentNumber
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        icGuid
        createdAt
        createdEpoch
        updatedAt
        amountGross
        amountNet1
        amountNet2
        amountNet3
        amountNetTotal
        amountVat1
        amountVat2
        amountVat3
        amountVatTotal
        appliedMapping
        bankName
        btBUMID
        btInvoiceRef
        btOrigin
        btReceiverRef
        btScanDate
        businessArea
        businessUnit
        companyID
        companyCode
        companyName
        companyNameOverride
        currency
        customerID
        deliveryDate
        deliveryTerms
        deliveryEndDate
        deliveryLocation
        deliveryInstruction
        documentNumber
        documentSubType
        documentType
        dueDate
        exportApproved
        exportMapping
        exportType
        externalSystem
        externalSystemID
        forceValid
        gAccount
        gAmount
        invoiceDate
        invoiceNumber
        invoiceType
        integrationDetail
        ivsProcessID
        lastError
        noteNumber
        noteToSupplier
        opCo
        owner
        orderDate
        orderType
        paymentTerms
        pdfUploadedFrontEnd
        purchasingCategory
        poCreator
        poOwner
        purpose
        receivedMessageID
        receiverAddress1
        receiverAddress2
        receiverCity
        receiverCompanyName
        receiverCompanyCode
        receiverContractNumber
        receiverCountryCode
        receiverContactName
        receiverContactFirstName
        receiverContactPhoneNumber
        receiverContactEmailAddress
        receiverGLN
        receiverKVK
        receiverNetServiceId
        receiverOrdernumber
        receiverPostalCode
        receiverVatId
        receiverEmailAddress
        receiverEndPointIsoCode
        receiverEndPointID
        remark
        returnDate
        returnEmailAddress
        returnStatus
        s3ResourceAttachment {
          s3Resource
        }
        s3ResourceWebserviceResponse
        s3ResourceExportedDocument
        s3ResourceGeneratedImage
        s3ResourceOrgImage
        s3ResourceOrgXML
        source
        status
        secondaryStatus
        senderAddress1
        senderAddress2
        senderCompanyCode
        senderContractNumber
        senderCountryCode
        senderContactName
        senderContactFirstName
        senderContactPhoneNumber
        senderContactEmailAddress
        senderDocCode
        senderDocNum
        senderEmailAddress
        senderGLN
        senderIBAN
        senderName
        senderNote
        senderReference
        senderTerms
        senderPostalCode
        senderVatId
        subscriptionType
        supplierAddress1
        supplierAddress2
        supplierBBAN
        supplierCity
        supplierCountryCode
        supplierDescription
        supplierEmailAddress
        supplierGLN
        supplierIBAN
        supplierKVK
        supplierName
        supplierNetServiceId
        supplierCode
        supplierRecognitionInput
        supplierOrdernumber
        supplierPostalCode
        supplierVatId
        swiftCode
        vatRate1
        vatRate2
        vatRate3
        history {
          id
          createdAt
          user
          description
          type
          documentChanges {
            field
            oldValue
            newValue
          }
        }
        lines {
          id
          lineNumber
          lineNetTotal
          lineVatRate
          lineVatAmount
          lineUnitPrice
          lineQuantity
          lineDescription
          lineUoM
          lineArticleCode
          lineSupplierArticleCode
          lineOrderNumber
          lineDiscountRate
          lineDiscountAmount
          lineDiscountName
          lineFreeNumber1
          lineFreeNumber2
          lineFreeNumber3
          lineFreeText1
          lineFreeText2
          lineFreeText3
        }
        locked
        lockedBy
        lockedSince
        hasBeenReProcessed
        sendScanReportToBasware
        scanReportCode
        scanReportDescription
        scanReportComment
        title
      }
      nextToken
    }
  }
`;
export const statusCustomerIDIndex = /* GraphQL */ `
  query StatusCustomerIDIndex(
    $status: Int!
    $customerID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusCustomerIDIndex(
      status: $status
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        icGuid
        createdAt
        createdEpoch
        updatedAt
        amountGross
        amountNet1
        amountNet2
        amountNet3
        amountNetTotal
        amountVat1
        amountVat2
        amountVat3
        amountVatTotal
        appliedMapping
        bankName
        btBUMID
        btInvoiceRef
        btOrigin
        btReceiverRef
        btScanDate
        businessArea
        businessUnit
        companyID
        companyCode
        companyName
        companyNameOverride
        currency
        customerID
        deliveryDate
        deliveryTerms
        deliveryEndDate
        deliveryLocation
        deliveryInstruction
        documentNumber
        documentSubType
        documentType
        dueDate
        exportApproved
        exportMapping
        exportType
        externalSystem
        externalSystemID
        forceValid
        gAccount
        gAmount
        invoiceDate
        invoiceNumber
        invoiceType
        integrationDetail
        ivsProcessID
        lastError
        noteNumber
        noteToSupplier
        opCo
        owner
        orderDate
        orderType
        paymentTerms
        pdfUploadedFrontEnd
        purchasingCategory
        poCreator
        poOwner
        purpose
        receivedMessageID
        receiverAddress1
        receiverAddress2
        receiverCity
        receiverCompanyName
        receiverCompanyCode
        receiverContractNumber
        receiverCountryCode
        receiverContactName
        receiverContactFirstName
        receiverContactPhoneNumber
        receiverContactEmailAddress
        receiverGLN
        receiverKVK
        receiverNetServiceId
        receiverOrdernumber
        receiverPostalCode
        receiverVatId
        receiverEmailAddress
        receiverEndPointIsoCode
        receiverEndPointID
        remark
        returnDate
        returnEmailAddress
        returnStatus
        s3ResourceAttachment {
          s3Resource
        }
        s3ResourceWebserviceResponse
        s3ResourceExportedDocument
        s3ResourceGeneratedImage
        s3ResourceOrgImage
        s3ResourceOrgXML
        source
        status
        secondaryStatus
        senderAddress1
        senderAddress2
        senderCompanyCode
        senderContractNumber
        senderCountryCode
        senderContactName
        senderContactFirstName
        senderContactPhoneNumber
        senderContactEmailAddress
        senderDocCode
        senderDocNum
        senderEmailAddress
        senderGLN
        senderIBAN
        senderName
        senderNote
        senderReference
        senderTerms
        senderPostalCode
        senderVatId
        subscriptionType
        supplierAddress1
        supplierAddress2
        supplierBBAN
        supplierCity
        supplierCountryCode
        supplierDescription
        supplierEmailAddress
        supplierGLN
        supplierIBAN
        supplierKVK
        supplierName
        supplierNetServiceId
        supplierCode
        supplierRecognitionInput
        supplierOrdernumber
        supplierPostalCode
        supplierVatId
        swiftCode
        vatRate1
        vatRate2
        vatRate3
        history {
          id
          createdAt
          user
          description
          type
          documentChanges {
            field
            oldValue
            newValue
          }
        }
        lines {
          id
          lineNumber
          lineNetTotal
          lineVatRate
          lineVatAmount
          lineUnitPrice
          lineQuantity
          lineDescription
          lineUoM
          lineArticleCode
          lineSupplierArticleCode
          lineOrderNumber
          lineDiscountRate
          lineDiscountAmount
          lineDiscountName
          lineFreeNumber1
          lineFreeNumber2
          lineFreeNumber3
          lineFreeText1
          lineFreeText2
          lineFreeText3
        }
        locked
        lockedBy
        lockedSince
        hasBeenReProcessed
        sendScanReportToBasware
        scanReportCode
        scanReportDescription
        scanReportComment
        title
      }
      nextToken
    }
  }
`;
export const recognitionStatusCustomerIDIndex = /* GraphQL */ `
  query RecognitionStatusCustomerIDIndex(
    $status: Int!
    $customerID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecognitionDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recognitionStatusCustomerIDIndex(
      status: $status
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        icGuid
        customerID
        createdAt
        createdEpoch
        advanced
        updatedAt
        documentSubType
        companyCode
        documentType
        integrationDetail
        lastError
        status
        secondaryStatus
        ocrStatus
        validator
        receivedMessageID
        bodyS3Resource
        s3ResourceOrgImage
        s3ResourceAttachment {
          s3Resource
        }
        locked
        lockedBy
        lockedSince
        history {
          id
          createdAt
          user
          description
          type
          documentChanges {
            field
            oldValue
            newValue
          }
        }
        remark
        exportType
        supplierIBAN {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        gAccount {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        supplierVatId {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        supplierCoc {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        credit {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        invoiceNumber {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        dueDate {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        invoiceDate {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        receiverOrdernumber {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        receiverContractNumber {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        currency {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountNet1 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountVat1 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        vatRate1 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountNet2 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountVat2 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        vatRate2 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountNet3 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountVat3 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        vatRate3 {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountNetTotal {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountVatTotal {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
        amountGross {
          value
          originalText
          ocrConfidence
          confidence
          page
          width
          height
          left
          top
        }
      }
      nextToken
    }
  }
`;
export const customerIDCreatedAtReceivedIndex = /* GraphQL */ `
  query CustomerIDCreatedAtReceivedIndex(
    $customerID: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceivedMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerIDCreatedAtReceivedIndex(
      customerID: $customerID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customerID
        attribute1
        attribute2
        attribute3
        attribute4
        attribute5
        bodyS3Resource
        domain
        errorText
        hasIgnoredFiles
        ignoreds3ResourceAttachment {
          s3Resource
        }
        s3ResourceAttachment {
          s3Resource
        }
        secondaryStatus
        senderAddress
        status
        subject
        companyCode
        integrationID
        recipientAddress
        forceValid
        processID
        processName
        history {
          id
          createdAt
          user
          description
          type
          documentChanges {
            field
            oldValue
            newValue
          }
        }
        test
      }
      nextToken
    }
  }
`;
export const statusCustomerIDReceivedIndex = /* GraphQL */ `
  query StatusCustomerIDReceivedIndex(
    $status: Int!
    $customerID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReceivedMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusCustomerIDReceivedIndex(
      status: $status
      customerID: $customerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customerID
        attribute1
        attribute2
        attribute3
        attribute4
        attribute5
        bodyS3Resource
        domain
        errorText
        hasIgnoredFiles
        ignoreds3ResourceAttachment {
          s3Resource
        }
        s3ResourceAttachment {
          s3Resource
        }
        secondaryStatus
        senderAddress
        status
        subject
        companyCode
        integrationID
        recipientAddress
        forceValid
        processID
        processName
        history {
          id
          createdAt
          user
          description
          type
          documentChanges {
            field
            oldValue
            newValue
          }
        }
        test
      }
      nextToken
    }
  }
`;
